import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as usersService from "../../services/usermgmt.services";
import * as eventServices from "../../services/events.services";
import CustomPagination from "../../common/custompagination";
import Sorting from "../../common/sorting";
import Loader from "../../common/loader";
import Breadcrums from "../../common/breadcrumbs";
import $ from "jquery";
import { GENDER, SWAL_SETTINGS, showFilterlist, STAFF_ROLE, EMAIL_TEMPLATE_CATEGORY } from "../../../../utils/Constants";
import {
  capitalizeFirstLetter,
  formateDate,
  globalLoader,
  handleServerValidations,
  hasPermission,
  removeCommas,
  writeMyExcel
} from "../../../../utils/commonfunction";
import StatusFilter from "../../common/statusFilter";
import CustomRangepicker from "../../common/rangepicker";
import { Button, Modal, Toggle } from "rsuite";
import SendMedia from "../../common/sentmedia";
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import { Tooltip, Whisper } from 'rsuite';
import * as templateServices from "../../services/email.template";
import * as senderEmailsServices from "../../services/senderEmail.services";
import {ContentBlocksOptions  } from '../../services/contentBlocks.services';


const UsersTable = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [csvHeaders] = useState(["First Name", "Middle Name", "Last Name", "Email", "Salutation", "Subscribed for news letter", "Status", "Created"]);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [inviteModal, setInviteModal] = useState(false);
  const [search, setSearch] = useState({});
  const [allUsersExportFlag] = useState(false);
  const [globalsearch, setGlobalSearch] = useState("");
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Users", url: "" },
  ];
  const [resetdate, setResetDate] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [user_id, setUser_id] = useState("");
  /********** MULTI SELECT >>>>>>********* */
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedAllData, setSelectedAllData] = useState(false);
  const [eventOptions, setEventOptions] = useState([]);
  const [userAssignData, setUserAssignData] = useState([]);
  const [deletedData, setDeletedData] = useState([]);
  const selectAllCheckbox = useRef();
  const [fileType] = useState(Object.keys(GENDER).map((item => {
    return {
      label: item, value: GENDER[item]
    }
  })));
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [notesData, setNotesData] = useState("");
  const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
  const [senderEmailOptions, setSenderEmailOptions] = useState([]);
  const [contentClockOptions, setContentClockOptions] = useState([]);

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      usersService
        .List(formData)
        .then((data) => {
          setDataLength(data.data.total_records);
          // setSerialNo(data.data.offset);
          setList(data && data.data && data.data.list ? data.data.list : []);
          //   console.log("data.data.data_ids", data.data.data_ids);
          /********** MULTI SELECT >>>>>>********* */
          setSelectedAllData(
            data && data.data && data.data.data_ids ? data.data.data_ids : []
          );
          /********** MULTI SELECT <<<<<<********* */
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [location, statsupdate, sorting, search, globalsearch, open, itemperpage, params.pgno]);

  useEffect(() => {
    eventServices.Options({invitaton_only:1}).then((response) => {
      if (response.success) {
        // console.log("response",response)
        setEventOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  useEffect(() => {
    templateServices.Options({category:EMAIL_TEMPLATE_CATEGORY.USER_INVITATION}).then((response) => {
      if (response.success) {
        setEmailTemplateOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, []);


  useEffect(() => {
    senderEmailsServices.Options().then((response) => {
      if (response.success) {
        setSenderEmailOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, []);

  useEffect(() => {
    ContentBlocksOptions().then((response) => {
      if (response.success) {
        setContentClockOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])
  const viewfunction = (row) => {
    navigate(`/admin/user-management/users/view/${row._id}?page=1&chat_page=1`);
  };

  const ChangeStatus = (data, Num) => {
    let ids = Array.isArray(data) ? data : [data];
    const formData = new FormData();
    formData.append("o_id", JSON.stringify(ids));
    formData.append("status", Num);
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        usersService
          .Status(formData)
          .then((response) => {
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setStatusUpdate(!statsupdate);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("error===>");
          });
      }
    });
  };

  const ChangeSubscribe = (data, status) => {
    let ids = Array.isArray(data) ? data : [data];
    const formData = new FormData();
    formData.append("o_id", JSON.stringify(ids));
    formData.append("action", status ? 1 : 0);
    usersService.Subscribe(formData).then((response) => {
      if (response.success) {
        setStatusUpdate(!statsupdate);
        Swal.fire({
          icon: "success",
          text: response.message,
          ...SWAL_SETTINGS,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: handleServerValidations(response),
          ...SWAL_SETTINGS,
        });
      }
    });
  };

  /********** MULTI SELECT >>>>>>********* */
  useEffect(() => {
    if (selectedRecords.length === datalength) {
      selectAllCheckbox.current.indeterminate = false;
      selectAllCheckbox.current.checked = true;
    } else if (selectedRecords.length) {
      selectAllCheckbox.current.indeterminate = true;
    } else {
      selectAllCheckbox.current.indeterminate = false;
    }
  }, [selectedRecords]);
  const selectRecord = (userData, check) => {
    if (check) {
      setSelectedRecords((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedRecords((previousState) =>
        previousState.filter((item) => item !== userData._id)
      );
    }
  };
  const handleSelectAll = (check) => {

    if (check) {
      setSelectedRecords(selectedAllData);
    } else {
      setSelectedRecords([]);
    }
  };
  const isSelected = (data) => {
    return selectedRecords.filter((item) => data?._id === item).length > 0;
  };
  /********** MULTI SELECT <<<<<<<<<********* */

  const inviteValidationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required").test("no-spaces", "Title is required", (value) => value.trim()),
    event_id: Yup.string().required("Event is required"),
    email_template:Yup.string()
  });

  function Deletefunction(data) {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formdata = new FormData();
        let ids = Array.isArray(data) ? data : [data];
        // formdata.append("o_id", row._id);
        formdata.append("o_id", JSON.stringify(ids));
        usersService
          .Delete(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              /** removing deleted ids from selectedRecords */
              setSelectedRecords((data) => data.filter((item) => { return ids.indexOf(item) === -1 }));

            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  }

  // sorting function start
  const handleSort = (e, column) => {
    setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // search or filter function
  const prepareSearch = (key, value) => {
    console.log('keykey', key)
    console.log('valuevalue', value)
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    console.log('srrsrrsr', sr)
    setSearch(sr);
  };
  // search or filer end

  /** This function is used to export a particular user's data */
  const exportUser = (row) => {
    let status = row?.status === 1 ? "Active" : "Inactive";
    let subscribed_for_newsletter = row?.subscribed_for_newsletter === true ? "Subscribed" : "Not Subscribed";
    let data = [
      csvHeaders,
      [
        row?.first_name,
        row?.middle_name,
        row?.last_name,
        row?.email,
        row?.gender ? capitalizeFirstLetter(row?.gender) : "",
        subscribed_for_newsletter,
        status,
        formateDate(row?.createdAt),
      ],
    ];
    writeMyExcel(data, `${row?.first_name}.xlsx`).then(() => {
      globalLoader(false);
    }).catch((err) => {
      console.log(err);
    });
    // return data;
  };

  /** This function is used to export all user's data */
  const exportAllUsers = (event_id, done) => {
    globalLoader(true);
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", datalength);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("search", JSON.stringify(search));
    formData.append("global_search", globalsearch);
    usersService
      .List(formData)
      .then((response) => {
        let data = response && response?.data && response?.data?.list ? response.data.list : [];
        let rows = data.map((item => {
          return [
            removeCommas(item?.first_name),
            removeCommas(item?.middle_name),
            removeCommas(item?.last_name),
            removeCommas(item?.email),
            item?.gender ? capitalizeFirstLetter(item.gender) : "N/A",
            item?.subscribed_for_newsletter === true ? "Subscribed" : "Not Subscribed",
            item?.status === 1 ? "Active" : "Inactive",
            removeCommas(formateDate(item?.createdAt))
          ];
        }));
        // setAllUsersToExport(rows);
        writeMyExcel([csvHeaders, ...rows], `users-report.xlsx`).then(() => {
          setTimeout(() => {
            globalLoader(false);
          }, 1000)
        }).catch((err) => {
          console.log(err);
          globalLoader(false);
        });
        // done(true);
      }).catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  };


  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    // reset customrangepicker & customstatusfilter state using jquery//
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
    $("#resetFilter")[0].selectedIndex = 0;
    $("#event_id")[0].selectedIndex = 0;
    // $("#role")[0].selectedIndex = 0;
  };

  const onSentClick = (e) => {
  usersService
    .AssignMedia({ media: JSON.stringify(selectedMedia), o_id: user_id, deleted_files: JSON.stringify(deletedData) })
    .then((response) => {
      // console.log("response", response);
      if (response.success) {
        Swal.fire({
          icon: "success",
          text: response.message,
          ...SWAL_SETTINGS,
        });
        setOpen(false);
      } else {
        Swal.fire({
          icon: "error",
          text: handleServerValidations(response),
          ...SWAL_SETTINGS,
        });
      }
    })
    .catch((error) => {
      console.log("error", error);
    });
  };

  const goToEdit = (row) => {
    navigate(`/admin/user-management/users/${params.pgno}/edit/${row._id}`)
  }

  const goToDuplicate = (row) => {
    navigate(`/admin/user-management/users/add`, { state: row });
  };

  const handleFilterChange = (e,type) => {
    if (e.target.value) {
      type==='salutation' ? prepareSearch("gender", e.target.value) : prepareSearch("event_id", e.target.value)
    } else {
      type==='salutation' ? prepareSearch("gender", "") : prepareSearch("event_id", "e.target.value")
    }
  };
  const openNotesModal = (data) => {
    setNotesData(data);
    setShowNotesModal(true);
  };



  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {/* table section */}
      <div className="animation_fade">
        <div className="card custom-card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="main-content-label">USERS</h6>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0 me-3">
                  <div className="form-group mb-0 rangepicker_container filter_design">
                    <i className="fa fa-search calender_icon"></i>
                    <input
                      type="search"
                      className="form-control"
                      value={globalsearch}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="me-3">
                  <CustomRangepicker
                    GetDateRange={(e) => {
                      prepareSearch("createdAt", e);
                    }}
                    resetdate={resetdate}
                  />
                </div>
                <div className="me-3">
                  <StatusFilter
                    data={showFilterlist}
                    prepareSearch={prepareSearch}
                  />
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select className="form-control cp" onChange={(e)=>{handleFilterChange(e,'salutation')}} id="resetFilter" style={{ width: "130px" }}>
                        <option value="">
                          Salutation
                        </option>
                        {fileType && fileType.length > 0 && fileType.map((option, i) => {
                          return (
                            <option key={i} value={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select className="form-control cp"  onChange={(e)=>{handleFilterChange(e,'event_id')}} id="event_id" style={{ width: "150px" }}>
                        <option value="" label="Select event">
                          Select{" "}
                        </option>
                        {eventOptions &&
                          eventOptions.length > 0 &&
                          eventOptions.map((option, i) => {
                            return (
                              <option key={i} value={option._id}>
                                {option.title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>


                <button
                  type="reset"
                  value="Reset"
                  onClick={resetFilter}
                  className="btn btn-warning float-right mr-2"
                >
                  Reset Filter
                </button>
                <button className="btn ripple btn-main-primary signbtn mr-2" onClick={() => navigate(`/admin/user-management/users/add`)}>Add New</button>
                {
                  allUsersExportFlag ?
                    <>
                      <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength === 0} onClick={exportAllUsers}>Export Report</button>
                    </>
                    : <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength === 0} onClick={exportAllUsers}>Export Report</button>
                }

              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                <thead>
                  <tr>
                    <th className="position-relative select_head">
                      <div className="select-check-container">
                        <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                          className="ckbox  cp">
                          <input
                            ref={selectAllCheckbox}
                            id="selectAllCheck"
                            checked={
                              datalength && datalength === selectedRecords.length
                            }
                            onChange={(e) => handleSelectAll(e.target.checked)} type="checkbox" />
                          <span></span>
                        </label>
                      </div>
                    </th>
                    <th style={{ width: "50px" }}>Image</th>
                    <th>
                      <div className="sorting_column">
                        <span>Salutation</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="gender"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="sorting_column">
                        <span>Name</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="name"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="sorting_column">
                        <span>Email</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="email"
                        />
                      </div>
                    </th>
                    <th className="status_head">SUBSCRIBE</th>
                    <th className="created_head">
                      <div className="sorting_column">
                        <span>Created Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          defaultSorting={defaultSorting}
                          column="createdAt"
                        />
                      </div>
                    </th>
                    {/* <th>Created By</th>
                    <th>Updated By</th> */}
                    <th className="status_head">Status</th>
                    <th className="action_head">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={10}>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {list.length ? (
                        list.map((row, i) => {
                          // console.log("row",row)
                          return (
                            <tr
                              key={i}
                              className={isSelected(row) ? "selected" : ""}
                            >
                              <td className="position-relative">
                                <div className="select-check-container">
                                  <label htmlFor={row?._id + "input"} style={{ lineHeight: 'unset' }}
                                    className="ckbox cp">
                                    <input
                                      id={row?._id + "input"}
                                      checked={isSelected(row)}
                                      onChange={(e) =>
                                        selectRecord(row, e?.target?.checked)
                                      }
                                      type="checkbox"
                                      className="form-check select-check cp" />
                                    <span></span>
                                  </label>
                                </div>
                              </td>

                              <td>
                                {row?.profile_image ? (
                                  <div className="row_image">
                                    <img
                                      className="rounded"
                                      alt="profile"
                                      src={row.profile_image}
                                    />
                                  </div>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td className="text-capitalize">{row?.gender ? row.gender : "N/A"}</td>
                              <td>
                                {row?.notes ? (
                                  <div className="d-flex">
                                    {row?.name ? row.name : "N/A"}
                                    <span
                                      className='ms-2 cp'
                                      onClick={() => openNotesModal(row?.notes)}
                                    >
                                      <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Notes</Tooltip>}>
                                        <i className="fa fa-comments"></i>
                                      </Whisper>
                                    </span>
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    {row?.name ? row.name : "N/A"}
                                  </div>
                                )}
                              </td>
                              <td>{row?.email ? row.email : "N/A"}</td>
                              {hasPermission('/admin/user-management/users/subscribe') ?
                                <td>
                                  {
                                    <Toggle
                                      defaultChecked={
                                        row?.subscribed_for_newsletter
                                      }
                                      onChange={() => {
                                        ChangeSubscribe(row?._id, row?.subscribed_for_newsletter ? false : true);
                                      }}
                                    />
                                  }
                                </td>
                                :
                                <td>{row?.subscribed_for_newsletter ? <span className="badge badge-success">Subscribed</span> : <span className="badge badge-danger"> Not Subscribed</span>}</td>
                              }
                              <td>
                                {row.createdAt
                                  ? formateDate(row.createdAt)
                                  : "N/A"}
                              </td>
                              {hasPermission('/admin/user-management/users/status') ?
                                <td>
                                  {row.status === 1 ? (
                                    <button
                                      className="btn ripple btn-main-primary signbtn"
                                      onClick={() => {
                                        ChangeStatus(row?._id, 0);
                                      }}
                                    >
                                      Active
                                    </button>
                                  ) : (
                                    <button
                                      className="btn ripple btn-secondary"
                                      onClick={() => {
                                        ChangeStatus(row?._id, 1);
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                </td>
                                :
                                <td>{row?.status === 1 ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>}</td>
                              }
                              <td>
                                <div className="d-flex">
                                  <button
                                    className="btn ripple btn-main-primary signbtn"
                                    onClick={() => {
                                      viewfunction(row);
                                    }}
                                  >
                                    View
                                  </button>

                                  {
                                    row?.role === STAFF_ROLE.USER ?
                                      <>
                                        <button className="btn ripple btn-success mlAction" onClick={() => { goToEdit(row) }}>Edit</button>
                                        {hasPermission('/admin/user-management/users/delete') ?
                                          <button
                                            className="btn ripple btn-secondary mlAction"
                                            onClick={() => {
                                              Deletefunction(row?._id);
                                            }}
                                          >
                                            Delete
                                          </button>
                                          : null}
                                        {hasPermission('/admin/user-management/users/more') ?
                                          <div className="btn-group mlAction">
                                            <button
                                              type="button"
                                              className="btn btn-dark dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              More
                                            </button>
                                            <ul className="dropdown-menu">
                                              {hasPermission('/admin/user-management/users/assign_media') ?
                                                <li>
                                                  <a style={{ color: "#647194" }}
                                                    href="/"
                                                    className="dropdown-item"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      setOpen(true);
                                                      setUser_id(row?._id);
                                                      setUserAssignData(row?.media);
                                                    }}
                                                  >
                                                    Share Media
                                                  </a>
                                                </li>
                                                : null}
                                              {hasPermission('/admin/user-management/users/duplicate') ?

                                                <li>
                                                  <a style={{ color: "#647194" }}
                                                    href="/"
                                                    className="dropdown-item"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      goToDuplicate(row)
                                                    }}
                                                  >
                                                    Duplicate
                                                  </a>
                                                </li>
                                                : null}
                                              {hasPermission('/admin/user-management/users/export') ?

                                                <li>
                                                  {/* <CSVLink
                                                    className="dropdown-item"
                                                    filename={`user-report.xlsx`}
                                                    data={exportUser(row)}
                                                  >
                                                    Export
                                                  </CSVLink> */}
                                                  <a style={{ color: "#647194" }}
                                                    href="/"
                                                    className="dropdown-item"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      exportUser(row)
                                                    }}
                                                  >
                                                    Export
                                                  </a>
                                                </li>
                                                : null}
                                            </ul>
                                          </div>
                                          : null}
                                      </>
                                      : null
                                  }


                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10} className="text-center">
                            No records
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              id="example1_info"
              role="status"
              aria-live="polite"
            >
              <b>Total Records : {datalength ? datalength : "0"}</b>
            </div>
            {datalength && datalength > 0 ? (
              <CustomPagination
                datalength={datalength}
                itemperpage={itemperpage}
                currentPage={page}
                setPage={setPage}
                pageRoute={[
                  { name: "User", path: "/admin/user-management/users/list" },
                ]}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
      {selectedRecords.length ? (
        <div className="bulk_actions">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ fontSize: "23px", color: "#35b159" }}
              ></i>
              <h6 className="ms-2 mt-1 mb-0">
                Selected {selectedRecords.length}{" "}
                {selectedRecords.length > 1 ? "records" : "record"} of{" "}
                {datalength}
              </h6>
            </div>
            <div>
              <ul>
                {hasPermission('/admin/user-management/users/subscribe') ?
                  <li>
                    <button
                      className="btn ripple btn-main-primary signbtn"
                      onClick={() => {
                        ChangeSubscribe(selectedRecords, true);
                      }}
                    >
                      Subscribe
                    </button>
                  </li>
                  : null}
                {hasPermission('/admin/user-management/users/subscribe') ?
                  <li>
                    <button
                      className="btn ripple btn-secondary"
                      onClick={() => {
                        ChangeSubscribe(selectedRecords, false);
                      }}
                    >
                      Unsubscribe
                    </button>
                  </li>
                  : null}
                {hasPermission('/admin/user-management/users/status') ?

                  <li>
                    <button
                      className="btn ripple btn-main-primary signbtn"
                      onClick={() => {
                        ChangeStatus(selectedRecords, 1);
                      }}
                    >
                      Active
                    </button>
                  </li>
                  : null}
                {hasPermission('/admin/user-management/users/status') ?

                  <li>
                    <button
                      className="btn ripple btn-secondary"
                      onClick={() => {
                        ChangeStatus(selectedRecords, 0);
                      }}
                    >
                      Inactive
                    </button>
                  </li>
                  : null}
                {hasPermission('/admin/user-management/users/delete') ?
                  <li>
                    <button
                      className="btn ripple btn-secondary"
                      onClick={() => {
                        Deletefunction(selectedRecords);
                      }}
                    >
                      Delete
                    </button>
                  </li>
                  : null}
                {hasPermission('/admin/user-management/users/Invite_users') ?

                  <li>
                    <button
                      className="btn ripple btn-info "
                      onClick={() => {
                        setInviteModal(true)
                      }}
                    >
                      Invite Users
                    </button>
                  </li>
                  : null}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/***********MULTI SELECTION OPTIONS <<<<<<*************/}




      {/* Work of Invite user by select */}

      <Modal
        size={"lg"}
        className="invitation-modal modal-height-auto"
        open={inviteModal}
        onClose={() => {
          setInviteModal(false);
        }}
        backdrop={'static'}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Invite User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: "",
                event_id: "",
                email_template:"",
                content_block_id:"",
                sender_email_name:""
              }}
              validationSchema={inviteValidationSchema}
              onSubmit={(values, { resetForm }) => {
                usersService.ListByIds({ 'o_id': JSON.stringify(selectedRecords) }).then((response) => {
                  if (response.success) {
                    globalLoader(true);
                    let formData = new FormData();
                    formData.append("event_id", values?.event_id);
                    formData.append("type", "send_now");
                    formData.append("invitation_title", values.title);
                    formData.append("users", JSON.stringify(response?.data?.list));
                    formData.append("email_template", values.email_template);
                    if(values.sender_email_name){
                      formData.append("sender_email_name",values.sender_email_name );
                    }
                    if(values.content_block_id){
                      formData.append("content_block_id",values.content_block_id );
                    }
                    eventServices
                      .InviteUsers(formData)
                      .then((response) => {
                        globalLoader(false);
                        if (response?.success) {
                          Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                          });
                          setStatusUpdate(!statsupdate);
                          setInviteModal(false);
                        } else {
                          Swal.fire({
                            icon: "error",
                            text: response.message,
                            ...SWAL_SETTINGS,
                          });
                        }
                      })
                      .catch((error) => {
                        globalLoader(false);
                        Swal.fire({
                          icon: "error",
                          text: error,
                          ...SWAL_SETTINGS,
                        });
                      });
                  }
                }).catch((error) => {
                  console.log("error", error)
                })

              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                    <div className="row">
                      <div className="col-lg-6 text-center form-group mt-4">
                        <label className="text-left d-flex">Select Event:<span className="requirestar">*</span></label>
                        <div className="form-group">
                          <div className="select-down-arrow">
                            <select
                              className="form-control select2"
                              value={values.event_id}
                              onChange={(e) => setFieldValue('event_id', e.target.value)}
                            >
                              <option value="" label="Select">
                                Select{" "}
                              </option>
                              {eventOptions &&
                                eventOptions.length > 0 &&
                                eventOptions.map((option, i) => {
                                  return (
                                    <option key={i} value={option._id}>
                                      {option.title}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <span className='text-danger d-flex text-left'><ErrorMessage name={"event_id"} /></span>
                        </div>
                      </div>
                      <div className="col-lg-6 text-center form-group ps-0 mt-4">
                        <label className="text-left d-flex">Invitation Title:<span className="requirestar">*</span></label>
                        <input
                          name='title'
                          id='title'
                          type='text'
                          className='form-control'
                          value={values.title}
                          onChange={handleChange}

                        />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"title"} />
                        </span>
                      </div>
                      <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">Select Email Template:</label>
                      <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.email_template}
                            onChange={(e) => {setFieldValue('email_template', e.target.value);
                          }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {emailTemplateOptions &&
                              emailTemplateOptions.length > 0 &&
                              emailTemplateOptions.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.title}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"email_template"} /></span>
                      </div>
                    </div>

                    <div className="col-lg-6 text-center form-group">
                        <label className="text-left d-flex">Select Sender Email Name:</label>
                        <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.sender_email_name}
                            onChange={(e) => {setFieldValue('sender_email_name', e.target.value);
                          }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {senderEmailOptions &&
                              senderEmailOptions.length > 0 &&
                              senderEmailOptions.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.email_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"content_block_id"} /></span>
                      </div>
                      </div>
                      <div className="col-lg-6 text-center form-group">
                        <label className="text-left d-flex">Select Content Block:</label>
                        <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.content_block_id}
                            onChange={(e) => {setFieldValue('content_block_id', e.target.value);
                          }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {contentClockOptions &&
                              contentClockOptions.length > 0 &&
                              contentClockOptions.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.title}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"content_block_id"} /></span>
                      </div>
                      </div>
                    </div>
                    <Modal.Footer className="mt-4">
                      <Button
                        onClick={() => {
                          setInviteModal(false);
                        }}
                        appearance="subtle"
                      >
                        Close
                      </Button>
                      <Button
                        type="submit"
                        appearance="primary"
                      >
                        Submit
                      </Button>
                    </Modal.Footer>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      {/* Work of Invite user by select */}


      {open ? (
        <SendMedia
          setOpen={setOpen}
          chooseMedia={setSelectedMedia}
          deletedMediaProps={setDeletedData}
          onSubmit={(e) => onSentClick(e)}
          successButtonText={"Send"}
          assignedMedia={userAssignData}
        />
      ) : (
        <></>
      )}

      <Modal
        size={"lg"} open={showNotesModal}
        onClose={() => setShowNotesModal(false)}
        backdrop={'static'}
        className="modal-height-auto">
        <Modal.Header className="mb-3">
          <Modal.Title>Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="border p-4 rounded">
            <p className="mb-3">{notesData && notesData}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowNotesModal(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UsersTable;
