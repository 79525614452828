import React, { useEffect, useRef, useState } from "react";
import { Field, Formik, ErrorMessage, FieldArray } from "formik";
import Swal from "sweetalert2";
import {
  useNavigate,
  useParams,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import * as eventsService from "../../services/events.services";
import * as questionSetServices from "../../services/questionset";
import CustomCkeditor from "../../common/customeditor";
import { CATEGORY_OPTIONS, CURRENCY, EVENT_TYPES, STAFF_ROLE, SWAL_SETTINGS, QUESTION_TYPES, SURVEY_TYPES, } from "../../../../utils/Constants";
import { SpecialCharacter, handleServerValidations } from "../../../../utils/commonfunction";
import CustomDatetimepicker from "../../common/customDatetimepicker";
import * as Yup from "yup";
import { TagPicker, Toggle } from "rsuite";
import * as staffService from "../../services/staff.services";
import { DatePicker } from "rsuite";
import { Tooltip, Whisper } from "rsuite";
import { useSelector } from "react-redux";

const Event = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [previewimage, setPreviewImage] = useState("");
  const [previewimagegerman, setPreviewImageGerman] = useState("");
  const [queSetList, setQuesetList] = useState([]);
  const [backUserQueSetList, setbackUserQueSetList] = useState([]);
  const [leadCoordinatorQueSetList, setLeadCoordinatorQueSetList] = useState(
    []
  );
  const [tradeFairQueSetList, setTradeFairQueSetList] = useState([]);
  const [boothAttendantsQueSetList, setBoothAttendantsQueSetList] = useState(
    []
  );
  const [infoCounterQueSetList, setInfoCounterQueSetList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [showdefault, setShowDefault] = useState({});
  const [secondaryRegistrantsAllowed, setSecondaryRegistrantsAllowed] =
    useState(false);
  const [qrCodeRequired, setQrCodeRequired] = useState(false);
  const [qrCodeLead, setQrCodeLead] = useState(false);
  const [qrCodeInfoCounter, setQrInfoCounter] = useState(false);
  const [qrCodeBoothAttendants, setQrBoothAttendants] = useState(false);
  const [accomodationOffered, setAccomodationOffered] = useState(false);
  const [chooseInOutDate, setChooseInOutDate] = useState(false);
  const [chooseRoomCategory, setChooseRoomCategory] = useState(false);
  const [chooseEventAttendance, setChooseEventAttendance] = useState(false);
  const [visaInvitation, setVisaInvitation] = useState(false);
  const [dateLoaded, setDataLoaded] = useState(false);
  const [ckData, setCkData] = useState(params.id ? false : true);
  const [secondEmailAddressCC, setSecondEmailAddressCC] = useState(false);
  const [image, setImage] = useState();
  const [imageGerman, setImageGerman] = useState();
  const formikRef = useRef();
  const [id, setId] = useOutletContext();
  // const [statusBoothAttendants, setStatusBoothAttendants] = useState([]);
  const [lmBackOfficeList, setLMBackOfficeList] = useState([]);
  const [SISUsersOptionsList, setSISUsersOptionsList] = useState([]);
  const [infoCounterUsersOptionsList, setInfoCounterUsersOptionsList] = useState([]);
  const [leadCoordinatorList, setLeadCoordinatorList] = useState([]);
  const [otherEvents, setOtherEvents] = useState([]);
  
  const [eventInitialValues, setEventInitialValues] = useState({
    title: "",
    files: "",
    files_de: "",
    hostingCompany: "",
    startDate: "",
    endDate: "",
    eventManager: [],
    lm_back_office_id: [],
    booth_attendant_users: [],
    info_counter_users: [],
    guestAvailable: false,
    leadManagement: false,
    SISManagement: false,
    description:
      showdefault && showdefault.description ? showdefault.description : "",
    questionGroupPrimary: "",
    back_office_registration: "",
    lead_coordinator_registration: "",
    trade_fair_questionnaire: "",
    info_counter_registration: "",
    booth_attendants_registration: "",
    currency: "",
    questionGroupSecondary: "",
    totalQuantity: "",
    price: "",
    tax: "",
    purchaseTimesStartDate: "",
    purchaseTimesEndDate: null,
    event_type: "",
    status_booth_attendants: showdefault && showdefault.status_booth_attendants && showdefault.status_booth_attendants.length ? showdefault.status_booth_attendants : [""],
    // options: [""],
    lead_coordinator_id:[],
    other_events:[],

  });

  const [eventType] = useState(
    Object.keys(EVENT_TYPES).map((item) => {
      return {
        label: item,
        value: EVENT_TYPES[item],
      };
    })
  );

  useEffect(() => {
    if (state) {
      setEventInitialValues({
        title: state && state?.title ? state?.title : "",
        // title_de: state && state?.title_de ? state?.title_de : "",
        files: "",
        files_de: "",
        hostingCompany: state && state?.hosting_company ? state?.hosting_company : "",
        startDate: state && state?.start_date ? new Date(state?.start_date) : "",
        endDate: state && state?.end_date ? new Date(state?.end_date) : "",
        eventManager:
          state && state?.event_manager_id ? state.event_manager_id : [],
        lm_back_office_id:
          state && state?.lm_back_office_id ? state.lm_back_office_id : [],
        booth_attendant_users: state && state?.booth_attendant_users ? state.booth_attendant_users : [],
        info_counter_users: state && state?.info_counter_users ? state.info_counter_users : [],
        guestAvailable:
          state && state?.guest_can_add_secondary
            ? state.guest_can_add_secondary
            : false,
        leadManagement:
          state && state?.lead_management ? state.lead_management : false,
        SISManagement:
          state && state?.sis_management ? state.sis_management : false,
        description: state && state?.description ? state.description : "",
        // description_de: state && state?.description_de ? state.description_de : "",
        questionGroupPrimary:
          state && state?.question_group_primary
            ? state.question_group_primary
            : "",
        back_office_registration:
          state && state?.back_office_registration
            ? state.back_office_registration
            : "",
        lead_coordinator_registration:
          state && state?.lead_coordinator_registration
            ? state.lead_coordinator_registration
            : "",
        trade_fair_questionnaire:
          state && state?.trade_fair_questionnaire
            ? state.trade_fair_questionnaire
            : "",
        info_counter_registration:
          state && state?.info_counter_registration
            ? state.info_counter_registration
            : "",
        booth_attendants_registration:
          state && state?.booth_attendants_registration
            ? state.booth_attendants_registration
            : "",
        currency: state && state?.currency ? state.currency : "",
        questionGroupSecondary:
          state && state?.question_group_secondary
            ? state.question_group_secondary
            : "",
        totalQuantity:
          state &&
          state?.event_quota_data &&
          state?.event_quota_data?.total_quantity
            ? state?.event_quota_data?.total_quantity
            : "",
        price:
          state && state?.event_quota_data && state?.event_quota_data?.price
            ? state?.event_quota_data?.price
            : "",
        tax:
          state && state?.event_quota_data && state?.event_quota_data?.tax
            ? state?.event_quota_data?.tax
            : "",
        purchaseTimesStartDate:
          state &&
          state?.event_quota_data &&
          state?.event_quota_data?.start_date
            ? new Date(state?.event_quota_data?.start_date)
            : "",
        purchaseTimesEndDate:
          state && state?.event_quota_data && state?.event_quota_data?.end_date
            ? new Date(state?.event_quota_data?.end_date)
            : null,
        event_type: state && state?.event_type ? state.event_type : "",
        status_booth_attendants: state && state?.status_booth_attendants && state?.status_booth_attendants.length ? state.status_booth_attendants : [""],
        lead_coordinator_id:state && state?.lead_coordinator_id ? state.lead_coordinator_id : [],
        other_events: state && state?.other_events ? state.other_events : [],
      });
      setDataLoaded(true);
      setCkData(true);
    }
    setDataLoaded(true);
  }, []);

  const data =
    staffList &&
    staffList.map((item) => ({ label: item?.name, value: item?._id }));

  const getQuestionList = (type = null) => {
    let values = {};
    if (type) {
      values.category = type;
    }
    questionSetServices
      .Options({type:SURVEY_TYPES.QUESTION_SET})
      .then((response) => {
        if (response && response.success) {
          switch (type) {
            case "lead_coordinator_registration":
              setLeadCoordinatorQueSetList(
                response && response.data ? response.data : []
              );
              break;
            case "trade_fair_questionnaire":
              setTradeFairQueSetList(
                response && response.data ? response.data : []
              );
              break;
            case "lm_back_office_registration":
              setbackUserQueSetList(
                response && response.data ? response.data : []
              );
              break;
            case "booth_attendants_registration":
              setBoothAttendantsQueSetList(
                response && response.data ? response.data : []
              );
              break;
            case "info_counter_registration":
              setInfoCounterQueSetList(
                response && response.data ? response.data : []
              );
              break;
            default:
              setQuesetList(response && response.data ? response.data : []);
              break;
          }
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  };

  const getLMBackOfficeList = () => {
    staffService
      .Options({ role: STAFF_ROLE.LM_BACK_OFFICE })
      .then((response) => {
        if (response && response.success) {
          const lmBackOfficeData =
            response &&
            response?.data.map((item) => ({
              label: item?.name,
              value: item?._id,
            }));
          setLMBackOfficeList(lmBackOfficeData);
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  };


  const getLeadCordinatorList = (isSisManagement) => {
    let json = {role:STAFF_ROLE.LEAD_COORDINATOR}
    if( isSisManagement==true){
      json = {role: {'$in':[STAFF_ROLE.LEAD_COORDINATOR,STAFF_ROLE.BOOTH_ATTENDANTS]} }
    }
    console.log("json",json)
    staffService
      .Options(json)
      .then((response) => {
        if (response && response.success) {
          const data =
            response &&
            response?.data.map((item) => ({
              label: item?.name,
              value: item?._id,
            }));
            setLeadCoordinatorList(data);
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  };
  
  const getSISUsersOptionsList = () => {
    staffService.Options({ role: STAFF_ROLE.BOOTH_ATTENDANTS }).then((response) => {
        if (response && response.success) {
          const lmBackOfficeData =
            response &&
            response?.data.map((item) => ({
              label: item?.name,
              value: item?._id,
            }));
            setSISUsersOptionsList(lmBackOfficeData);
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  };

  const getInfoCounterUsersOptionsList = () => {
    staffService.Options({ role: STAFF_ROLE.INFO_COUNTER }).then((response) => {
        if (response && response.success) {
          const infoCounterUserData =
            response &&
            response?.data.map((item) => ({
              label: item?.name,
              value: item?._id,
            }));
            setInfoCounterUsersOptionsList(infoCounterUserData);
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  };


  const getOtherEventsList = () => {
    eventsService
      .Options({ event_id: params.id? params.id:''})
      .then((response) => {
        if (response && response.success) {
          const userEmailsData =
            response &&
            response?.data.map((item) => ({
              label: item?.title,
              value: item?._id,
            }));
          setOtherEvents(userEmailsData);
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  };

  useEffect(() => {
    getQuestionList();
    getQuestionList(CATEGORY_OPTIONS[0].value);
    getQuestionList(CATEGORY_OPTIONS[1].value);
    getQuestionList(CATEGORY_OPTIONS[2].value);
    getQuestionList(CATEGORY_OPTIONS[3].value);
    getQuestionList(CATEGORY_OPTIONS[4].value);
    getLMBackOfficeList();
    getSISUsersOptionsList();
    getInfoCounterUsersOptionsList();
    getLeadCordinatorList();
    getOtherEventsList();
    staffService
      .Options()
      .then((response) => {
        if (response && response.success) {
          setStaffList(response && response.data);
          if (params.id) {
            getData(params.id, response.data);
          } else if (id) {
            getData(id, response.data);
          }
        }
      })
      .catch((error) => {
        console.log("err", error);
      });
  }, []);

  const getData = (id, data) => {
    eventsService.Details(id).then(response => {
      if (response && response.success) {
        setShowDefault(response.data ? response.data : []);
          setTimeout(() => {
            formikRef?.current?.setFieldValue("title", response?.data?.title);
            formikRef?.current?.setFieldValue("files", response?.data?.image);
            setPreviewImage(response?.data?.image);
            formikRef?.current?.setFieldValue("files_de", response?.data?.image_de);
            setPreviewImageGerman(response?.data?.image_de);
            formikRef?.current?.setFieldValue(
              "hostingCompany",
              response?.data?.hosting_company
            );
            formikRef?.current?.setFieldValue(
              "startDate",
              new Date(response?.data?.start_date)
            );
            formikRef?.current?.setFieldValue(
              "endDate",
              new Date(response?.data?.end_date)
            );
            formikRef?.current?.setFieldValue(
              "eventManager",
              response?.data?.event_manager_id
            );
            formikRef?.current?.setFieldValue(
              "lm_back_office_id",
              response?.data?.lm_back_office_id
            );
            formikRef?.current?.setFieldValue(
              "booth_attendant_users",
              response?.data?.booth_attendant_users
            );
            formikRef?.current?.setFieldValue(
              "info_counter_users",
              response?.data?.info_counter_users
            );
            formikRef?.current?.setFieldValue(
              "guestAvailable",
              response?.data?.guest_can_add_secondary
            );
            formikRef?.current?.setFieldValue(
              "leadManagement",
              response?.data?.lead_management
            );
            formikRef?.current?.setFieldValue(
              "SISManagement",
              response?.data?.sis_management
            );
            setSecondaryRegistrantsAllowed(
              response?.data?.secondary_registrants_allowed_to_agenda_options
            );
            setQrCodeRequired(response?.data?.is_qr_required);
            // setQrCodeLead(response?.data?.enable_qr_for_leads);
            setQrInfoCounter(response?.data?.enable_qr_for_info_counter);
            setQrBoothAttendants(
              response?.data?.enable_qr_for_booth_attendants
            );
            formikRef?.current?.setFieldValue(
              "description",
              response?.data?.description
            );
            setAccomodationOffered(response?.data?.accommodation_offered);
            setChooseInOutDate(response?.data?.choice_arrival_departure_date);
            setChooseRoomCategory(response?.data?.choice_room_category);
            setChooseEventAttendance(
              response?.data?.choice_event_attendance_date
            );
            setVisaInvitation(response?.data?.askto_visa_verification);
            formikRef?.current?.setFieldValue(
              "questionGroupPrimary",
              response?.data?.question_group_primary
            );
            // formikRef?.current?.setFieldValue(
            //   "back_office_registration",
            //   response?.data?.back_office_registration
            // );
            // formikRef?.current?.setFieldValue(
            //   "lead_coordinator_registration",
            //   response?.data?.lead_coordinator_registration
            // );
            // formikRef?.current?.setFieldValue(
            //   "trade_fair_questionnaire",
            //   response?.data?.trade_fair_questionnaire
            // );
            formikRef?.current?.setFieldValue(
              "info_counter_registration",
              response?.data?.info_counter_registration
            );
            formikRef?.current?.setFieldValue(
              "booth_attendants_registration",
              response?.data?.booth_attendants_registration
            );
            formikRef?.current?.setFieldValue(
              "currency",
              response?.data?.currency?.code
            );
            if (response?.data?.question_group_secondary) {
              formikRef?.current?.setFieldValue(
                "questionGroupSecondary",
                response?.data?.question_group_secondary
              );
            }
            formikRef?.current?.setFieldValue(
              "totalQuantity",
              response?.data?.event_quota_data?.total_quantity
            );
            formikRef?.current?.setFieldValue(
              "price",
              response?.data?.event_quota_data?.price
            );
            formikRef?.current?.setFieldValue(
              "tax",
              response?.data?.event_quota_data?.tax
            );
            formikRef?.current?.setFieldValue(
              "purchaseTimesStartDate",
              new Date(response?.data?.event_quota_data?.start_date)
            );
            formikRef?.current?.setFieldValue(
              "purchaseTimesEndDate",
              new Date(response?.data?.event_quota_data?.end_date)
            );
            formikRef?.current?.setFieldValue(
              "event_type",
              response?.data?.event_type || ""
            );
            if (response?.data?.lead_management) {
              formikRef?.current?.setFieldValue(
                "back_office_registration",
                response?.data?.back_office_registration
              );
              formikRef?.current?.setFieldValue(
                "lead_coordinator_registration",
                response?.data?.lead_coordinator_registration
              );
              formikRef?.current?.setFieldValue(
                "trade_fair_questionnaire",
                response?.data?.trade_fair_questionnaire
              );
              setQrCodeLead(response?.data?.enable_qr_for_leads);
            }
            if(response?.data?.sis_management && response?.data?.status_booth_attendants?.length){
              formikRef?.current?.setFieldValue(
                "status_booth_attendants",
                response?.data?.status_booth_attendants
              );
            }
            formikRef?.current?.setFieldValue(
              "other_events",
              response?.data?.other_events
            );
            // setTimeout(() => {
            //   setCkData(true);
            // }, 1000);
            formikRef?.current?.setFieldValue(
              "lead_coordinator_id",
              response?.data?.lead_coordinator_id
            );
            getLeadCordinatorList(response?.data?.sis_management)
          }, 100);
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  };

  const eventValidationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .test("no-spaces", "Title is required", (value) => value.trim()),
    files: Yup.mixed()
      .required("Image is required")
      .test("fileType", "Unsupported file format", (value) => {
        if (!value) return false;
        if (value?.name) {
          if (
            value?.name?.includes(".jpg") ||
            value?.name?.includes(".png") ||
            value?.name?.includes(".jpeg") ||
            value?.name?.includes(".gif") ||
            value?.name?.includes(".webp")
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }),
    hostingCompany: Yup.string()
      .required("Hosting company is required")
      .test("no-spaces", "Hosting company is required", (value) =>
        value.trim()
      ),
    startDate: Yup.string()
      .required("Start date is required")
      .test("no-spaces", "Start date is required", (value) => value.trim()),
    endDate: Yup.string()
      .required("End date is required")
      .test("no-spaces", "End date is required", (value) => value.trim()),
    // eventManager: Yup.mixed().required("Event manager is required").test("no-spaces", "Event manager is required", (value) => value.trim()),
    eventManager: Yup.mixed()
      .required("Event manager is required")
      .test("no-spaces", "Event manager is required", (value) => {
        return String(value).trim().length > 0;
      }),
    event_type: Yup.string()
      .required("Event type is required")
      .test("no-spaces", "Event type is required", (value) => {
        return String(value).trim().length > 0;
      }),
    guestAvailable: Yup.boolean(),
    description: Yup.string()
      .required("Description is required")
      .test("no-spaces", "Description required", (value) => value.trim()),
    questionGroupPrimary: Yup.string()
      .required("Please select question group")
      .test("no-spaces", "Please select question group", (value) =>
        value.trim()
      ),
    currency: Yup.string().required("Please select currency"),
    totalQuantity: Yup.string()
      .required("Total quantity is required")
      .test("no-spaces", "Total quantity is required", (value) => value.trim()),
    price: Yup.string()
      .strict(false)
      .trim()
      .matches(/^[0-9]+$/, "Decimal values is not allowed in price "),
    tax: Yup.string()
      .strict(false)
      .trim()
      .matches(/^[0-9]+$/, "Decimal values is not allowed in tax")
      .test("check-price", "Please enter price first", (value) => {
        if (value && value > 0) {
          if (formikRef?.current?.values?.price) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .test("check-price", "Please enter tax", (value) => {
        if (formikRef?.current?.values?.price) {
          if (value) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }),
    purchaseTimesStartDate: Yup.string()
      .required("Start date is required")
      .test("no-spaces", "Start date is required", (value) => value.trim()),
    purchaseTimesEndDate: Yup.string()
      .required("End date is required")
      .test("no-spaces", "End date is required", (value) => value.trim()),
    questionGroupSecondary: Yup.string().when("guestAvailable", {
      is: true,
      then: () => Yup.string().required("Please select question group"),
      otherwise: () => Yup.string().nullable(true),
    }),
    back_office_registration: Yup.string().when("leadManagement", {
      is: true,
      then: () =>
        Yup.string().required("Please select back office registration"),
      otherwise: () => Yup.string().nullable(true),
    }),
    lead_coordinator_registration: Yup.string().when("leadManagement", {
      is: true,
      then: () =>
        Yup.string().required("Please select lead coordinator registration"),
      otherwise: () => Yup.string().nullable(true),
    }),
    trade_fair_questionnaire: Yup.string().when("leadManagement", {
      is: true,
      then: () =>
        Yup.string().required("Please select trade fair questionnaire"),
      otherwise: () => Yup.string().nullable(true),
    }),
    info_counter_registration: Yup.string().when("SISManagement", {
      is: true,
      then: () => Yup.string().required("Please select info counter"),
      otherwise: () => Yup.string().nullable(true),
    }),
    booth_attendants_registration: Yup.string().when("SISManagement", {
      is: true,
      then: () => Yup.string().required("Please select booth attendants"),
      otherwise: () => Yup.string().nullable(true),
    }),
    // user_emails: Yup.string().trim()
    // .matches(
    //   /^([\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4})(,\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4})*$/,
    //   { message: "Invalid email format. Please use comma-separated emails." }
    // )
    // .test('check-emails', 'Invalid email format. Please use comma-separated emails.', function(value) {
    //   if (value) {
    //     const emails = value.split(',').map(email => email.trim());
    //     // Check each email for validity
    //     const isValid = emails.every(email => Yup.string().email().isValidSync(email));
    //     return isValid;
    //   }
    //   return true; // If no value, bypass this validation
    // })
  });

  const onFormSubmit = (values) => {
    setSubmitted(true);
    let formData = new FormData();
    formData.append("title", values.title);
    formData.append("currency", values.currency);
    formData.append("hosting_company", values.hostingCompany);
    formData.append("start_date", values.startDate);
    formData.append("end_date", values.endDate);
    formData.append("event_manager_id", JSON.stringify(values.eventManager));
    formData.append("guest_can_add_secondary", values.guestAvailable);
    formData.append("lead_management", values.leadManagement);
    formData.append("sis_management", values.SISManagement);
    formData.append(
      "secondary_registrants_allowed_to_agenda_options",
      secondaryRegistrantsAllowed
    );
    formData.append("is_qr_required", qrCodeRequired);
    // formData.append("enable_qr_for_leads", qrCodeLead);
    formData.append("description", values.description);
    formData.append("accommodation_offered", accomodationOffered);
    formData.append("choice_arrival_departure_date", chooseInOutDate);
    formData.append("choice_room_category", chooseRoomCategory);
    formData.append("choice_event_attendance_date", chooseEventAttendance);
    formData.append("askto_visa_verification", visaInvitation);
    formData.append("question_group_primary", values.questionGroupPrimary);
    // formData.append( "back_office_registration", values.back_office_registration);
    // formData.append("lead_coordinator_registration", values.lead_coordinator_registration);
    // formData.append("trade_fair_questionnaire",values.trade_fair_questionnaire);
    formData.append("askto_second_email", secondEmailAddressCC);
    if (values.leadManagement == true) {
      formData.append("enable_qr_for_leads", qrCodeLead);
      formData.append(
        "lm_back_office_id",
        JSON.stringify(values.lm_back_office_id)
      );
      formData.append(
        "back_office_registration",
        values.back_office_registration
      );
      formData.append(
        "lead_coordinator_registration",
        values.lead_coordinator_registration
      );
      formData.append(
        "trade_fair_questionnaire",
        values.trade_fair_questionnaire
      );
      formData.append(
        "lead_coordinator_id",
        JSON.stringify(values.lead_coordinator_id)
      );
    }
    if (values.SISManagement == true || values.SISManagement == "true") {
      formData.append(
        "booth_attendant_users",
        JSON.stringify(values.booth_attendant_users)
      );
      formData.append(
        "info_counter_users",
        JSON.stringify(values.info_counter_users)
      );
      formData.append("enable_qr_for_info_counter", qrCodeInfoCounter);
      formData.append("enable_qr_for_booth_attendants", qrCodeBoothAttendants);
      formData.append(
        "info_counter_registration",
        values.info_counter_registration
      );
      formData.append(
        "booth_attendants_registration",
        values.booth_attendants_registration
      );
    }
    if(values.status_booth_attendants && (values.SISManagement == true || values.SISManagement == "true") ){
      formData.append("status_booth_attendants", JSON.stringify(values.status_booth_attendants));
    }
    if (values.questionGroupSecondary) {
      formData.append(
        "question_group_secondary",
        values.questionGroupSecondary
      );
    }
    formData.append("total_quantity", values.totalQuantity);
    if (values.price) {
      formData.append("price", values.price);
    } else {
      formData.append("price", 0);
    }

    if (values.tax) {
      formData.append("tax", values.tax);
    } else {
      formData.append("tax", 0);
    }
    formData.append("quota_start_date", values.purchaseTimesStartDate);
    formData.append("quota_end_date", values.purchaseTimesEndDate);
    formData.append("event_type", values.event_type);
    formData.append("other_events", JSON.stringify(values.other_events));
    if (!params.id) {
      formData.append("image", values.files);
      formData.append("image_de", values.files_de);
      eventsService
        .Add(formData)
        .then((response) => {
          if (response?.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            setId(response.data._id);
            setTimeout(() => {
              navigate(
                `/admin/event-management/events/add/hotels/${response.data._id}`
              );
            }, 2000);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            setSubmitted(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    } else {
      formData.append("o_id", params.id);
      if (image) {
        setImage(false);
        formData.append("image", values.files);
      }
      if (imageGerman) {
        setImageGerman(false);
        formData.append("image_de", values.files_de);
      }
      eventsService
        .Edit(formData)
        .then((response) => {
          if (response?.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            setTimeout(() => {
              navigate(
                `/admin/event-management/events/edit/hotels/${params.id}`
              );
            }, 2000);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            setSubmitted(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  };


  const isDateDisabled = (date, maxDate, minDate) => {
    return date < minDate || date > maxDate;
  };

  let globalData = useSelector((state) => state.globaldetail);

  const myGlobalData = useSelector((state) => state.globalData);
  const [getGlobalData, setGlobalData] = useState({});
  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  },[myGlobalData]);

  return (
    <>
      {dateLoaded ? (
        <Formik
          validateOnBlur={false}
          innerRef={formikRef}
          enableReinitialize
          initialValues={eventInitialValues}
          validationSchema={eventValidationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            onFormSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              {console.log("****",errors)}
              <div className="row">
                <div className="col-md-12 text-center form-group">
                  <label htmlFor="title" className="text-left d-flex">
                    Event title :<span className="requirestar">*</span>{" "}
                  </label>
                  <input
                    name="title"
                    id="title"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"title"} />
                  </span>
                </div>
                <div className="d-flex flex-column pb-3">
                  <label htmlFor="event_type" className="text-left">
                    Event type :<span className="requirestar">*</span>
                  </label>
                  <div className="select-down-arrow">
                    <Field
                      as="select"
                      id="event_type"
                      name={`event_type`}
                      className="form-control select2"
                      style={{ fontSize: 14 }}
                    >
                      <option value="" label="Select Type">
                        Select{" "}
                      </option>
                      {eventType &&
                        eventType.length > 0 &&
                        eventType.map((option, i) => {
                          return (
                            <option key={i} value={option.value}>
                              {SpecialCharacter(option.label)}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={`event_type`} />
                  </span>
                </div>
                <div className="col-md-6 text-center form-group ">
                  <label htmlFor="files" className="text-left d-flex">
                    Event image :<span className="requirestar">*</span>{" "}
                    <Whisper
                      placement="top"
                      controlId="control-id-hover"
                      trigger="hover"
                      speaker={<Tooltip>Recommended Size (1290x486)</Tooltip>}
                    >
                      <span className="field-more-info mt-1 ms-1 cp">?</span>
                    </Whisper>
                  </label>
                  <input
                    style={{}}
                    className="form-control h-auto"
                    id="files"
                    name="files"
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      setFieldValue(
                        "files",
                        event.currentTarget.files[0] || ""
                      );
                      setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))
                      if (params.id) {
                        setImage(true);
                      }
                    }}
                  />
                  <span className="text-danger d-flex text-left" id="errortext">
                    {touched.files && errors.files}
                  </span>
                  {previewimage ? (
                  <div className="d-flex mt-2">
                    <img src={previewimage} style={{ height: "100px" }} alt="Preview" />
                  </div>
                ) : (
                  ""
                )}
                </div>
                <div className="col-md-6 text-center form-group ">
                  <label htmlFor="files_de" className="text-left d-flex">
                    Event image german :
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Recommended Size (1290x486)</Tooltip>}>
                      <span className='field-more-info mt-1 ms-1 cp'>?</span>
                    </Whisper>
                  </label>
                  <input
                    style={{}}
                    className="form-control h-auto"
                    id="files_de"
                    name="files_de"
                    accept="image/*"
                    type="file"
                    onChange={(event) => {
                      setFieldValue(
                        "files_de",
                        event.currentTarget.files[0] || ""
                      );
                      setPreviewImageGerman(URL.createObjectURL(event.currentTarget.files[0]))
                      if (params.id) {
                        setImageGerman(true)
                      }
                    }}
                  />
                  <span
                    className="text-danger d-flex text-left"
                    id="errortext"
                  >
                    {touched.files_de && errors.files_de}
                  </span>
                  {previewimagegerman ? (
                  <div className="d-flex mt-2">
                    <img src={previewimagegerman} style={{ height: "100px" }} alt="Preview" />
                  </div>
                ) : (
                  ""
                )}
                </div>
                <div className="col-md-6 text-center form-group">
                  <label htmlFor="title" className="text-left d-flex">
                    Hosting company :<span className="requirestar">*</span>{" "}
                  </label>
                  <input
                    name="hostingCompany"
                    id="hostingCompany"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.hostingCompany}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"hostingCompany"} />
                  </span>
                </div>
                <div className="col-md-6 text-center form-group">
                  <label htmlFor="title" className="text-left d-flex">
                    Start date :<span className="requirestar">*</span>{" "}
                  </label>
                  <CustomDatetimepicker
                    placeholder={"Start date"}
                    setField={setFieldValue}
                    fieldname={`startDate`}
                    setFieldTouched={setFieldTouched}
                    formikRef={formikRef}
                    value={values.startDate}
                    correspondingEndDate={formikRef.current?.values?.endDate}
                    correspondingEndDateName={"endDate"}
                    endDateValue={values.endDate}
                  />

                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"startDate"} />
                  </span>
                </div>
                <div className="col-md-6 text-center form-group">
                  <label htmlFor="title" className="text-left d-flex">
                    End date :<span className="requirestar">*</span>{" "}
                  </label>
                  <CustomDatetimepicker
                    placeholder={"End date"}
                    setField={setFieldValue}
                    fieldname={`endDate`}
                    setFieldTouched={setFieldTouched}
                    formikRef={formikRef}
                    value={values.endDate}
                    endDate={true}
                    correspondingStartDate={
                      formikRef?.current?.values?.startDate
                    }
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"endDate"} />
                  </span>
                </div>

                <div className="col-md-12 text-center form-group">
                  <div className="d-flex justify-content-between pt-5 pb-3">
                    <label htmlFor="guestAvailable" className="text-left">
                      Is the guest can be added?
                    </label>
                    <Toggle
                      className="ms-3"
                      // defaultChecked={values.guestAvailable}
                      checked={values.guestAvailable}
                      onChange={() => {
                        if (values.guestAvailable) {
                          setFieldValue("guestAvailable", false);
                        } else {
                          setFieldValue("guestAvailable", true);
                        }
                      }}
                    />
                  </div>

                  {values.guestAvailable && (
                    <div className="d-flex justify-content-between pb-3">
                      <label
                        htmlFor="secondaryRegistrantsAllowed"
                        className="text-left"
                      >
                        Is secondary registrants allowed to register agenda
                        option?
                      </label>
                      <Toggle
                        className="ms-3"
                        checked={secondaryRegistrantsAllowed}
                        // defaultChecked={secondaryRegistrantsAllowed}
                        onChange={() => {
                          setSecondaryRegistrantsAllowed(
                            !secondaryRegistrantsAllowed
                          );
                        }}
                      />
                    </div>
                  )}

                  <div className="d-flex justify-content-between">
                    <label
                      htmlFor="qrCodeRequired"
                      className="text-left d-flex"
                    >
                      Wheather or not QR code is required
                      <Whisper
                        placement="top"
                        controlId="control-id-hover"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            QR Codes will be generated per User as a method of
                            check-in and check-out at events
                          </Tooltip>
                        }
                      >
                        <span className="field-more-info mt-1 ms-1 cp">?</span>
                      </Whisper>
                    </label>
                    <Toggle
                      className="ms-3"
                      // defaultChecked={qrCodeRequired}
                      checked={qrCodeRequired}
                      onChange={() => {
                        setQrCodeRequired(!qrCodeRequired);
                      }}
                    />
                  </div>
                  {getGlobalData?.settings?.enable_lead_management === true ? (
                    <div className="d-flex justify-content-between mt-2">
                      <label
                        htmlFor="leadManagement"
                        className="text-left d-flex"
                      >
                        Lead Management
                        <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={<Tooltip>Lead Management</Tooltip>}
                        >
                          <span className="field-more-info mt-1 ms-1 cp">
                            ?
                          </span>
                        </Whisper>
                      </label>
                      <Toggle
                        className="ms-3"
                        checked={values.leadManagement}
                        onChange={() => {
                          if (values.leadManagement) {
                            setFieldValue("leadManagement", false);
                          } else {
                            setFieldValue("leadManagement", true);
                          }
                        }}
                      />
                    </div>
                  ) : null}
                  {getGlobalData?.settings?.enable_sis_management === true ? (
                    <div className="d-flex justify-content-between mt-2">
                      <label
                        htmlFor="leadManagement"
                        className="text-left d-flex"
                      >
                        Stand Information Standard(SIS)
                        <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>Stand Information Standard(SIS)</Tooltip>
                          }
                        >
                          <span className="field-more-info mt-1 ms-1 cp">
                            ?
                          </span>
                        </Whisper>
                      </label>
                      <Toggle
                        className="ms-3"
                        checked={values.SISManagement}
                        onChange={() => {
                          if (values.SISManagement) {
                            setFieldValue("SISManagement", false);
                            getLeadCordinatorList(false)
                          } else {
                            setFieldValue("SISManagement", true);
                            getLeadCordinatorList(true)
                          }
                        }}
                      />
                    </div>
                  ) : null}
                  {values?.leadManagement && (
                    <div className="d-flex justify-content-between mt-2">
                      <label htmlFor="qrCodeLead" className="text-left d-flex">
                        Enable QR-Code for Lead Coordinators
                        <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Enable QR-Code for Front End for Lead Coordinators
                            </Tooltip>
                          }
                        >
                          <span className="field-more-info mt-1 ms-1 cp">
                            ?
                          </span>
                        </Whisper>
                      </label>
                      <Toggle
                        className="ms-3"
                        checked={qrCodeLead}
                        onChange={() => {
                          setQrCodeLead(!qrCodeLead);
                        }}
                      />
                    </div>
                  )}
                  {values?.SISManagement && (
                    <div className="d-flex justify-content-between mt-2">
                      <label
                        htmlFor="qrCodeInfoCounter"
                        className="text-left d-flex"
                      >
                        Enable QR-Code for Info Counter
                        <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>Enable QR-Code for Info Counter</Tooltip>
                          }
                        >
                          <span className="field-more-info mt-1 ms-1 cp">
                            ?
                          </span>
                        </Whisper>
                      </label>
                      <Toggle
                        className="ms-3"
                        checked={qrCodeInfoCounter}
                        onChange={() => {
                          setQrInfoCounter(!qrCodeInfoCounter);
                        }}
                      />
                    </div>
                  )}
                  {values?.SISManagement && (
                    <div className="d-flex justify-content-between mt-2">
                      <label
                        htmlFor="qrCodeBoothAttendants"
                        className="text-left d-flex"
                      >
                        Enable QR-Code for Booth Attendants
                        <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Enable QR-Code for Booth Attendants
                            </Tooltip>
                          }
                        >
                          <span className="field-more-info mt-1 ms-1 cp">
                            ?
                          </span>
                        </Whisper>
                      </label>
                      <Toggle
                        className="ms-3"
                        checked={qrCodeBoothAttendants}
                        onChange={() => {
                          setQrBoothAttendants(!qrCodeBoothAttendants);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-md-6 text-center form-group">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <label
                        htmlFor="eventManager"
                        className="text-left d-flex"
                      >
                        Event manager:<span className="requirestar">*</span>{" "}
                      </label>
                      <div className="select-down-arrow text-left">
                        <TagPicker
                          data={data}
                          defaultValue={values.eventManager}
                          className="w-100 rsuite-input"
                          onChange={(e) => setFieldValue("eventManager", e)}
                          cleanable={false}
                          value={values.eventManager}
                        />
                      </div>
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={`eventManager`} />
                      </span>
                    </div>
                    {values?.leadManagement && (
                      <>
                      <div className="col-12 text-center form-group">
                        <label
                          htmlFor="lm_back_office_id"
                          className="text-left d-flex"
                        >
                          LM-Back Office:{" "}
                        </label>
                        <div className="select-down-arrow text-left">
                          <TagPicker
                            data={lmBackOfficeList}
                            defaultValue={values.lm_back_office_id}
                            className="w-100 rsuite-input"
                            onChange={(e) =>
                              setFieldValue("lm_back_office_id", e)
                            }
                            cleanable={false}
                            value={values.lm_back_office_id}
                          />
                        </div>
                      </div>
                       <div className="col-12 text-center form-group">
                       <label
                         htmlFor="lead_coordinator_id"
                         className="text-left d-flex"
                       >
                         Lead Coordinators:{" "}
                       </label>
                       <div className="select-down-arrow text-left">
                         <TagPicker
                           data={leadCoordinatorList}
                           defaultValue={values.lead_coordinator_id}
                           className="w-100 rsuite-input"
                           onChange={(e) =>
                             setFieldValue("lead_coordinator_id", e)
                           }
                           cleanable={false}
                           value={values.lead_coordinator_id}
                         />
                       </div>
                     </div>
                     </>
                    )}
                       {values?.SISManagement && (
                      <div className="col-12 text-center form-group">
                        <label
                          htmlFor="booth_attendant_users"
                          className="text-left d-flex"
                        >
                          Booth Attendants:{" "}
                        </label>
                        <div className="select-down-arrow text-left">
                          <TagPicker
                            data={SISUsersOptionsList}
                            defaultValue={values.booth_attendant_users}
                            className="w-100 rsuite-input"
                            onChange={(e) =>
                              setFieldValue("booth_attendant_users", e)
                            }
                            cleanable={false}
                            value={values.booth_attendant_users}
                          />
                        </div>
                      </div>
                    )}
                    {values?.SISManagement && (
                      <div className="col-12 text-center form-group">
                        <label
                          htmlFor="info_counter_users"
                          className="text-left d-flex"
                        >
                         Info Counter:{" "}
                        </label>
                        <div className="select-down-arrow text-left">
                          <TagPicker
                            data={infoCounterUsersOptionsList}
                            defaultValue={values.info_counter_users}
                            className="w-100 rsuite-input"
                            onChange={(e) =>
                              setFieldValue("info_counter_users", e)
                            }
                            cleanable={false}
                            value={values.info_counter_users}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {values?.SISManagement && (
                      <div className="col-lg-12 mb-2">
                        <label
                          htmlFor="status_booth_attendants"
                          className="text-left d-flex"
                        >
                          Status for booth attendants:{" "}
                        </label>
                        <FieldArray
                          name="status_booth_attendants"
                          render={(arrayHelpers) => (
                            <div className="row">
                              {values?.status_booth_attendants?.length > 0 && values?.status_booth_attendants.map((value,index) => (
                                <div className="col-lg-6" key={index}>
                                  <div className="text-center form-group">
                                    <div className="d-flex">
                                      <div
                                        className="status_booth_attendants_input"
                                        style={{ flex: 1 }}
                                      >
                                        <Field className="form-control select2" name={`status_booth_attendants[${index}]`} />
                                      </div>
                                      {values?.status_booth_attendants?.length > 1 && (
                                        <button
                                          type="button"
                                          className="ml-2 op_button danger"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <i className="ti-close"></i>
                                        </button>
                                      )}
                                      {index === values?.status_booth_attendants?.length - 1 && (
                                        <button
                                          type="button"
                                          className="ml-2 op_button success"
                                          onClick={() => arrayHelpers.push("")}
                                        >
                                          <i className="ti-plus"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        />
                        {/* <span className="text-danger d-flex text-left">
                          {errors.status_booth_attendants && touched.status_booth_attendants && errors.status_booth_attendants}
                        </span> */}
                      </div>
                    )}
                <div className="col-lg-12 text-center form-group">
                  <label htmlFor="subject" className="text-left d-flex">
                    Description:<span className="requirestar">*</span>
                  </label>
                  {ckData ? (
                    <CustomCkeditor
                      fieldname={"description"}
                      setFieldValue={setFieldValue}
                      value={values.description}
                      setFieldTouched={setFieldTouched}
                    />
                  ) : (
                    <></>
                  )}
                  <span className="text-danger d-flex text-left">
                    {touched.description && errors.description}
                  </span>
                </div>
                <h4 className="col-md-12 pt-3">Question settings</h4>
                <div className="col-md-6 text-center form-group">
                  <div className="d-flex justify-content-between pt-2 pb-3">
                    <label htmlFor="accomodationOffered" className="text-left">
                      Is accomodation offered?
                    </label>
                    <Toggle
                      className="ms-3"
                      checked={accomodationOffered}
                      onChange={() => {
                        setAccomodationOffered(!accomodationOffered);
                      }}
                    />
                  </div>

                  {accomodationOffered && (
                    <div className="d-flex justify-content-between pb-3">
                      <label htmlFor="chooseInOutDate" className="text-left">
                        User can choose arrival and departure date
                      </label>
                      <Toggle
                        className="ms-3"
                        // defaultChecked={chooseInOutDate}
                        checked={chooseInOutDate}
                        onChange={() => {
                          setChooseInOutDate(!chooseInOutDate);
                        }}
                      />
                    </div>
                  )}

                  {accomodationOffered && (
                    <div className="d-flex justify-content-between pb-3">
                      <label htmlFor="chooseRoomCategory" className="text-left">
                        User can choose room category
                      </label>
                      <Toggle
                        className="ms-3"
                        // defaultChecked={chooseRoomCategory}
                        checked={chooseRoomCategory}
                        onChange={() => {
                          setChooseRoomCategory(!chooseRoomCategory);
                        }}
                      />
                    </div>
                  )}

                  <div className="d-flex justify-content-between pb-3">
                    <label
                      htmlFor="chooseEventAttendance"
                      className="text-left"
                    >
                      Can the user choose event attendance dates?
                    </label>
                    <Toggle
                      className="ms-3"
                      // defaultChecked={chooseEventAttendance}
                      checked={chooseEventAttendance}
                      onChange={() => {
                        setChooseEventAttendance(!chooseEventAttendance);
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-between pb-3">
                    <label htmlFor="visaInvitation" className="text-left">
                      Is the visa invitation letter asked?
                    </label>
                    <Toggle
                      className="ms-3"
                      // defaultChecked={visaInvitation}
                      checked={visaInvitation}
                      onChange={() => {
                        setVisaInvitation(!visaInvitation);
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column pb-3">
                    <label htmlFor="questionGroupPrimary" className="text-left">
                      Primary registrant (Question set) :
                      <span className="requirestar">*</span>
                    </label>
                    <div className="select-down-arrow">
                      <Field
                        as="select"
                        id="questionGroupPrimary"
                        name={`questionGroupPrimary`}
                        className="form-control select2"
                        style={{ fontSize: 14 }}
                      >
                        <option value="" label="Select">
                          Select{" "}
                        </option>
                        {queSetList &&
                          queSetList.length > 0 &&
                          queSetList.map((option, i) => {
                            return (
                              <option
                                key={i}
                                value={option._id}
                                defaultValue={values.questionGroupPrimary}
                              >
                                {option.title}
                              </option>
                            );
                          })}
                      </Field>
                    </div>
                    <span className="text-danger d-flex text-left">
                      <ErrorMessage name={`questionGroupPrimary`} />
                    </span>
                  </div>
                  {values?.leadManagement && (
                    <div className="d-flex flex-column pb-3">
                      <label
                        htmlFor="lead_coordinator_registration"
                        className="text-left"
                      >
                        Lead coordinator registration (Question set) :
                        <span className="requirestar">*</span>
                      </label>
                      <div className="select-down-arrow">
                        <Field
                          as="select"
                          id="lead_coordinator_registration"
                          name={`lead_coordinator_registration`}
                          className="form-control select2"
                          style={{ fontSize: 14 }}
                        >
                          <option value="" label="Select">
                            Select{" "}
                          </option>
                          {leadCoordinatorQueSetList &&
                            leadCoordinatorQueSetList.length > 0 &&
                            leadCoordinatorQueSetList.map((option, i) => {
                              return (
                                <option
                                  key={i}
                                  value={option._id}
                                  defaultValue={
                                    values.lead_coordinator_registration
                                  }
                                >
                                  {option.title}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={`lead_coordinator_registration`} />
                      </span>
                    </div>
                  )}
                  {values?.leadManagement && (
                    <div className="d-flex flex-column pb-3">
                      <label
                        htmlFor="trade_fair_questionnaire"
                        className="text-left"
                      >
                        Trade fair questionnaire (Question set) :
                        <span className="requirestar">*</span>
                      </label>
                      <div className="select-down-arrow">
                        <Field
                          as="select"
                          id="trade_fair_questionnaire"
                          name={`trade_fair_questionnaire`}
                          className="form-control select2"
                          style={{ fontSize: 14 }}
                        >
                          <option value="" label="Select">
                            Select{" "}
                          </option>
                          {tradeFairQueSetList &&
                            tradeFairQueSetList.length > 0 &&
                            tradeFairQueSetList.map((option, i) => {
                              return (
                                <option
                                  key={i}
                                  value={option._id}
                                  defaultValue={values.trade_fair_questionnaire}
                                >
                                  {option.title}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={`trade_fair_questionnaire`} />
                      </span>
                    </div>
                  )}
                  {values?.leadManagement && (
                    <div className="d-flex flex-column pb-3">
                      <label
                        htmlFor="back_office_registration"
                        className="text-left"
                      >
                        Backoffice registration (Question set) :
                        <span className="requirestar">*</span>
                      </label>
                      <div className="select-down-arrow">
                        <Field
                          as="select"
                          id="back_office_registration"
                          name={`back_office_registration`}
                          className="form-control select2"
                          style={{ fontSize: 14 }}
                        >
                          <option value="" label="Select">
                            Select{" "}
                          </option>
                          {backUserQueSetList &&
                            backUserQueSetList.length > 0 &&
                            backUserQueSetList.map((option, i) => {
                              return (
                                <option
                                  key={i}
                                  value={option._id}
                                  defaultValue={values.back_office_registration}
                                >
                                  {option.title}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={`back_office_registration`} />
                      </span>
                    </div>
                  )}
                  {values?.SISManagement && (
                    <div className="d-flex flex-column pb-3">
                      <label
                        htmlFor="booth_attendants_registration"
                        className="text-left"
                      >
                        Booth Attendants (Question set) :
                        <span className="requirestar">*</span>
                      </label>
                      <div className="select-down-arrow">
                        <Field
                          as="select"
                          id="booth_attendants_registration"
                          name={`booth_attendants_registration`}
                          className="form-control select2"
                          style={{ fontSize: 14 }}
                        >
                          <option value="" label="Select">
                            Select{" "}
                          </option>
                          {boothAttendantsQueSetList &&
                            boothAttendantsQueSetList.length > 0 &&
                            boothAttendantsQueSetList.map((option, i) => {
                              return (
                                <option
                                  key={i}
                                  value={option._id}
                                  defaultValue={
                                    values.booth_attendants_registration
                                  }
                                >
                                  {option.title}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={`booth_attendants_registration`} />
                      </span>
                    </div>
                  )}
                  {values?.SISManagement && (
                    <div className="d-flex flex-column pb-3">
                      <label
                        htmlFor="info_counter_registration"
                        className="text-left"
                      >
                        Info Counter (Question set) :
                        <span className="requirestar">*</span>
                      </label>
                      <div className="select-down-arrow">
                        <Field
                          as="select"
                          id="info_counter_registration"
                          name={`info_counter_registration`}
                          className="form-control select2"
                          style={{ fontSize: 14 }}
                        >
                          <option value="" label="Select">
                            Select{" "}
                          </option>
                          {infoCounterQueSetList &&
                            infoCounterQueSetList.length > 0 &&
                            infoCounterQueSetList.map((option, i) => {
                              return (
                                <option
                                  key={i}
                                  value={option._id}
                                  defaultValue={
                                    values.info_counter_registration
                                  }
                                >
                                  {option.title}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={`info_counter_registration`} />
                      </span>
                    </div>
                  )}
                  <div className="d-flex justify-content-between pt-3">
                    <label htmlFor="secondEmailAddressCC" className="text-left">
                      Is the second email address as CC asked?
                    </label>
                    <Toggle
                      className="ms-3"
                      // defaultChecked={secondEmailAddressCC}
                      checked={secondEmailAddressCC}
                      onChange={() => {
                        setSecondEmailAddressCC(!secondEmailAddressCC);
                      }}
                    />
                  </div>

                  {values.guestAvailable && (
                    <div className="d-flex flex-column ">
                      <label htmlFor="select" className="text-left">
                        Secondary registrant (Question set) :
                        <span className="requirestar">*</span>
                      </label>
                      <div className="select-down-arrow">
                        <Field
                          as="select"
                          id="questionGroupSecondary"
                          name={`questionGroupSecondary`}
                          className="form-control select2"
                          style={{ fontSize: 14 }}
                        >
                          <option value="" label="Select">
                            Select{" "}
                          </option>
                          {queSetList &&
                            queSetList.length > 0 &&
                            queSetList.map((option, i) => {
                              return (
                                <option
                                  key={i}
                                  value={option._id}
                                  defaultValue={values.questionGroupSecondary}
                                >
                                  {option.title}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={`questionGroupSecondary`} />
                      </span>
                    </div>
                  )}
                </div>

                <h4 className="col-md-12">Event quota</h4>
                <div className="col-md-12 text-center form-group">
                  <label htmlFor="totalQuantity" className="text-left d-flex">
                    Total quantity :<span className="requirestar">*</span>{" "}
                  </label>
                  <input
                    name="totalQuantity"
                    id="totalQuantity"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.totalQuantity}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"totalQuantity"} />
                  </span>
                </div>

                <div className="d-flex flex-column pb-3">
                  <label htmlFor="currency" className="text-left">
                    Currency :<span className="requirestar">*</span>
                  </label>
                  <div className="select-down-arrow">
                    <Field
                      as="select"
                      id="currency"
                      name={`currency`}
                      className="form-control select2"
                      style={{ fontSize: 14 }}
                    >
                      <option value="" label="Select">
                        Select{" "}
                      </option>
                      {CURRENCY &&
                        CURRENCY.map((option, i) => {
                          return (
                            <option
                              key={i}
                              value={option.code}
                              defaultValue={values.currency}
                            >
                              {option.name} {"(" + option.sign + ")"}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={`currency`} />
                  </span>
                </div>

                <div className="col-md-6 text-center form-group">
                  <label htmlFor="price" className="text-left d-flex">
                    Price :<span className="requirestar"></span>{" "}
                    <Whisper
                      placement="top"
                      controlId="control-id-hover"
                      trigger="hover"
                      speaker={
                        <Tooltip>
                          Price excluding Tax - If Free, then 0 If Price is
                          offered Gross (eg. for events outside of Germany),
                          then Tax should be 0.
                        </Tooltip>
                      }
                    >
                      <span className="field-more-info mt-1 ms-1 cp">?</span>
                    </Whisper>
                  </label>
                  <input
                    name="price"
                    id="price"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.price}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"price"} />
                  </span>
                </div>

                <div className="col-md-6 text-center form-group">
                  <label htmlFor="tax" className="text-left d-flex">
                    Tax % :<span className="requirestar"></span>{" "}
                  </label>
                  <input
                    name="tax"
                    id="tax"
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tax}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"tax"} />
                  </span>
                </div>

                <h5 className="col-md-12 d-flex">
                  Purchase times
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        Purchase Dates describe the Start and End Date for the
                        Time Period is which Users can register for the event.
                      </Tooltip>
                    }
                  >
                    <span className="field-more-info mt-1 ms-1 cp">?</span>
                  </Whisper>
                </h5>
                <div className="col-md-6 text-center form-group">
                  <label
                    htmlFor="purchaseTimesStartDate"
                    className="text-left d-flex"
                  >
                    Start date :<span className="requirestar">*</span>{" "}
                  </label>
                  <CustomDatetimepicker
                    placeholder={"Start date"}
                    setField={setFieldValue}
                    fieldname={`purchaseTimesStartDate`}
                    setFieldTouched={setFieldTouched}
                    formikRef={formikRef}
                    value={values.purchaseTimesStartDate}
                    correspondingEndDate={
                      formikRef.current?.values?.purchaseTimesEndDate
                    }
                    correspondingEndDateName={"purchaseTimesEndDate"}
                    endDateValue={values.purchaseTimesEndDate}
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"purchaseTimesStartDate"} />
                  </span>
                </div>

                <div className="col-md-6 text-center form-group">
                  <label
                    htmlFor="purchaseTimesEndDate"
                    className="text-left d-flex"
                  >
                    End date :<span className="requirestar">*</span>{" "}
                  </label>
                  <DatePicker
                    className="w-100"
                    placeholder={"End date"}
                    fieldname={`purchaseTimesEndDate`}
                    value={values.purchaseTimesEndDate}
                    onChange={(e) => setFieldValue("purchaseTimesEndDate", e)}
                    formikRef={formikRef}
                    endDate={true}
                    shouldDisableDate={(date) =>
                      isDateDisabled(
                        date,
                        values.endDate,
                        values.purchaseTimesStartDate
                      )
                    }
                    oneTap={true}
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"purchaseTimesEndDate"} />
                  </span>
                </div>
                {/* <div className="col-md-12 text-center form-group">
                  <label
                    htmlFor="purchaseTimesStartDate"
                    className="text-left d-flex"
                  >
                    User Emails:<span className="requirestar">Add comma separted emails</span>
                  </label>
                  <input
                    name="user_emails"
                    id="user_emails"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.user_emails}
                    className="form-control"
                  />
                  <span className="text-danger d-flex text-left">
                    <ErrorMessage name={"user_emails"} />
                  </span>
                </div> */}
                  <div className="col-12 text-center form-group">
                        <label
                          htmlFor="other_events"
                          className="text-left d-flex"
                        >
                          Other Events:{" "}
                        </label>
                        <div className="select-down-arrow text-left">
                          <TagPicker
                            data={otherEvents}
                            defaultValue={values.other_events}
                            className="w-100 rsuite-input"
                            onChange={(e) =>
                              setFieldValue("other_events", e)
                            }
                            cleanable={false}
                            value={values.other_events}
                          />
                        </div>
                      </div>
                <div className="mt-5">
                  <button
                    className="btn btn-main-primary signbtn mr-2"
                    type="submit"
                    disabled={submitted ? true : null}
                  >
                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                    Submit
                  </button>
                  <button
                    className="btn ripple btn-secondary"
                    type="button"
                    disabled={submitted ? true : null}
                    onClick={() => navigate(-1)}
                  >
                    <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </>
  );
};

export default Event;
